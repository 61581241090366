export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);
  // console.log(a);

  return a;
};

export const RequiredIs = {
  roles: [1],
  service_term: [1, 2, 3, 4],
  service_type: [1, 2, 3],
  product_type: [1, 2, 3, 4],
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export function timeShow(date) {

  if (date) {
    const timePart = date.split("T")[1]; // Splitting to get the time part
    const utcDateTime = new Date(date); // Creating a date object in UTC
    const istDateTime = new Date(utcDateTime.setMinutes(utcDateTime.getMinutes() + 330)); // Adding 5 hours 30 minutes for IST
    const istTime = istDateTime.toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata', hour12: false }); // Formatting IST time

    return istTime;
  }
}

// export const IDS = {
//   Role: { List: 1, Add: 2, Edit: 3, Delete: 4 },
//   User: { List: 5, Add: 6, Edit: 7, Delete: 8 },
//   Country: { List: 9, Add: 10, Edit: 11, Delete: 12 },
//   State: { List: 13, Add: 14, Edit: 15, Delete: 16 },
//   City: { List: 17, Add: 18, Edit: 19, Delete: 20 },
//   Pincode: { List: 21, Add: 22, Edit: 23, Delete: 24 },
//   CountryCode: { List: 25, Add: 26, Edit: 27, Delete: 28 },
//   Category: { List: 29, Add: 30, Edit: 31, Delete: 32 },
//   SubCategory: { List: 33, Add: 34, Edit: 35, Delete: 36 },
//   ChildSubCategory: { List: 37, Add: 38, Edit: 39, Delete: 40 },
//   FAQCategory: { List: 41, Add: 42, Edit: 43, Delete: 44 },
//   FAQ: { List: 45, Add: 46, Edit: 47, Delete: 48 },
//   Brands: { List: 49, Add: 50, Edit: 51, Delete: 52 },
//   TaxType: { List: 53, Add: 54, Edit: 55, Delete: 56 },
//   TaxPercentage: { List: 57, Add: 58, Edit: 59, Delete: 60 },
//   CouponCode: { List: 61, Add: 62, Edit: 63, Delete: 64 },
//   Blogs: { List: 65, Add: 66, Edit: 67, Delete: 68 },
//   Banner: { List: 69, Add: 70, Edit: 71, Delete: 72 },
//   WebHeader: { List: 73, Add: 74, Edit: 75, Delete: 76 },
//   AppSetup: { List: 77, Add: 78, Edit: 79, Delete: 80 },
//   Product: { List: 87, Add: 88, Edit: 89, Delete: 90 },
//   WhyChooseUs: { List: 91, Add: 92, Edit: 93, Delete: 94 },
//   HappyCustomer: { List: 95, Add: 96, Edit: 97, Delete: 98 },
//   OurStory: { List: 99, Add: 100, Edit: 101, Delete: 102 },
//   AboutBanner: { List: 103, Add: 104, Edit: 105, Delete: 106 },
//   Order: { List: 107, Add: 108, Edit: 109, Delete: 110 },
//   PrivacyPolicy: { List: 212, Add: 213, Edit: 214, Delete: 215 },
//   Terms: { List: 216, Add: 217, Edit: 218, Delete: 219 },

//   ReturnedOrder: { List: 111, Edit: 112 },
//   OrderToRefund: { List: 113 },
//   BulkUpload: {
//     Country: 200,
//     State: 201,
//     City: 203,
//     Pincode: 204,
//     CountryCode: 205,
//     Category: 206,
//     SubCategory: 207,
//     ChildSubCategory: 208,
//     Brands: 210,
//     TaxType: 211,
//     TaxPercentage: 212
//   }

// }

export const IDS = {
  Role: { List: 1, Add: 2, Edit: 3, Delete: 4 },
  User: { List: 5, Add: 6, Edit: 7, Delete: 8 },
  Country: { List: 9, Add: 10, Edit: 11, Delete: 12 },
  State: { List: 13, Add: 14, Edit: 15, Delete: 16 },
  City: { List: 17, Add: 18, Edit: 19, Delete: 20 },
  Pincode: { List: 21, Add: 22, Edit: 23, Delete: 24 },
  CountryCode: { List: 25, Add: 26, Edit: 27, Delete: 28 },
  Category: { List: 29, Add: 30, Edit: 31, Delete: 32 },
  SubCategory: { List: 33, Add: 34, Edit: 35, Delete: 36 },
  ChildSubCategory: { List: 37, Add: 38, Edit: 39, Delete: 40 },
  FAQCategory: { List: 41, Add: 42, Edit: 43, Delete: 44 },
  FAQ: { List: 45, Add: 46, Edit: 47, Delete: 48 },
  Brands: { List: 49, Add: 50, Edit: 51, Delete: 52 },
  TaxType: { List: 53, Add: 54, Edit: 55, Delete: 56 },
  TaxPercentage: { List: 57, Add: 58, Edit: 59, Delete: 60 },
  CouponCode: { List: 61, Add: 62, Edit: 63, Delete: 64 },
  Blogs: { List: 65, Add: 66, Edit: 67, Delete: 68 },
  Banner: { List: 69, Add: 70, Edit: 71, Delete: 72 },
  WebHeader: { List: 73, Add: 74, Edit: 75, Delete: 76 },
  AppSetup: { List: 220, Add: 221, Edit: 222, Delete: 223 },
  Product: { List: 87, Add: 88, Edit: 89, Delete: 90 },
  WhyChooseUs: { List: 91, Add: 92, Edit: 93, Delete: 94 },
  HappyCustomer: { List: 95, Add: 96, Edit: 97, Delete: 98 },
  OurStory: { List: 99, Add: 100, Edit: 101, Delete: 102 },
  AboutBanner: { List: 103, Add: 104, Edit: 105, Delete: 106 },
  Order: { List: 107, Add: 108, Edit: 109, Delete: 110 },
  
  PrivacyPolicy: { List: 212, Add: 213, Edit: 214, Delete: 215 },
  Terms: { List: 216, Add: 217, Edit: 218, Delete: 219 },

  ReturnedOrder: { List: 111, Edit: 112 },
  OrderToRefund: { List: 113 },
  BulkUpload: {
    Country: 200,
    State: 201,
    City: 203,
    Pincode: 204,
    CountryCode: 205,
    Category: 206,
    SubCategory: 207,
    ChildSubCategory: 208,
    Brands: 210,
    TaxType: 211,
    TaxPercentage: 212
  }


}
