import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import plus from "../../../Components/assets/icons/a1.png";
import colunms from "../../../Components/assets/icons/LINES.png";
import pen from "../../../Components/assets/icons/pen.png";
import basket from "../../../Components/assets/icons/basket.png";
import search1 from "../../../Components/assets/icons/search.png";
import top from "../../../Components/assets/icons/top.png";
import Table from "react-bootstrap/Table";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header/Header";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Offcanvas from "react-bootstrap/Offcanvas";

import toast, { Toaster } from "react-hot-toast";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import AddOffCanvance from "./Add";
import EditOffCanvance from "./Edit";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Context } from "../../../utils/context";
import { post } from "jquery";
import { postData } from "../../../utils/api";
import { formatDate, timeShow } from "../../../utils/common";

library.add(fas);

// ********************************************************************************************************************************************************

const Tables = () => {
  const {
    getData,
    editStatusData,
    deleteData,
    ErrorNotify,
    isAllow,
    Per_Page_Dropdown,
    Select2Data,usertype
  } = useContext(Context);

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [show, setShowAdd] = useState(false);
  const [show1, setShowEdit] = useState(0);
  const [option, setOption] = useState();

  const [changeStatus, setChangeStatus] = useState();

  const [selectAllChecked, setSelectAllChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [customerName, setCustomerName] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [searchOrderStatus, setSearchOrderSatatus] = useState("");
  const [searchPaymentStatus, setSearchPaymentSatatus] = useState("");
  const [searchDate, setSearchDate] = useState("");

  const getDataAll = async () => {
    const response = await getData(
      `/order?page=${currentPage}&per_page=${perPage}&term=${search}&customerName=${customerName}&searchProduct=${searchProduct?.value || ""
      }&searchOrderStatus=${searchOrderStatus?.value || ""
      }&searchPaymentStatus=${searchPaymentStatus?.value || ""}&date=${searchDate ? formatDate(searchDate) : ""}`
    );
    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
    setOption(await Per_Page_Dropdown(response?.data?.totalEntries));
  };
  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    show,
    show1,
    customerName,
    searchProduct,
    searchOrderStatus,
    searchPaymentStatus,
    searchDate,
  ]);

  useEffect(() => {
    console.log("searchPaymentStatus", searchPaymentStatus);
  }, [searchPaymentStatus]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(`/order/${id}`);
    setChangeStatus(response);
  };

  // Delete module....................................................
  const [modalShow, setModalShow] = useState(false);
  const [orderData, setOrderData] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);
  const [recordToDeleteName, setRecordToDeleteName] = useState(null);

  const showDeleteRecord = async (id, name) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
    setRecordToDeleteName(name);
  };

  const handleSelectAll = async () => {
    await setSelectAllChecked(allChecked);
  };

  const handleChange = async (e) => {
    const { value, checked } = e.target;

    if (value === "selectAll") {
      if (checked) {
        handleSelectAll();
      } else {
        await setSelectAllChecked([]);
      }
    } else {
      if (checked) {
        await setSelectAllChecked([...selectAllChecked, Number(value)]);
      } else {
        await setSelectAllChecked(
          selectAllChecked.filter((e) => e !== Number(value))
        );
      }
    }
  };
  // Modal function
  const handleClose = () => setShowAdd(false);
  const handleShow = () => setShowAdd(true);

  const handleClose1 = () => setShowEdit(0);
  const handleShow1 = (id) => {
    setShowEdit(id);
  };

  const DeleteRecord = async () => {
    setShowDeleteModal(false);
    if (recordToDeleteId) {
      const response = await deleteData(`/order/${recordToDeleteId}`);
      await ErrorNotify(recordToDeleteName);
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle/
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
    col9: true,
    col10: true,
    col11: true,
    col12: true,
    col13: false,
    col14: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };
  const [products, setProducts] = useState({});
  const [orderStatus, setOrderStatus] = useState({});

  const GetAllProducts = async () => {
    const response = await getData("/all-products");

    if (response?.success) {
      setProducts(await Select2Data(response?.data, "product_id"));
    }
  };

  const GetAllOrdderStatus = async () => {
    const response = await getData("/all-order-status");

    if (response?.success) {
      setOrderStatus(await Select2Data(response?.data, "order_status_id"));
    }
  };

  useEffect(() => {
    GetAllProducts();
    GetAllOrdderStatus();
  }, []);

  useEffect(() => {
    console.log("selectAllChecked", selectAllChecked);
  }, [selectAllChecked]);

  const handleChangeStatus = async (id) => {
    try {
      if (selectAllChecked.length === 0) {
        alert("Please select at least one row");
        return; // Exit the function early if no rows are selected
      }

      const response = await postData("/order/updateStatus", {
        selectedIds: selectAllChecked,
        newStatusId: id,
      });

      if (response?.success) {
        alert("Order status updated successfully:", response.data);
        getDataAll();
      }

      console.log("Order status updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating order status:", error);
      // Handle errors if necessary
    }
  };

  const [user, setUser] = useState({})
  const GetUserData = async () => {
    const response = await getData(`/usersingleget`);
    if (response?.success) {
      console.log("user :-", response?.data);
      setUser(response?.data)
    }

  };
  useEffect(() => {
    GetUserData();
  }, []);

  const handleClick = (data) => {
    setOrderData(data);
    setModalShow(true);
  };
  return (
    <>
      <div className="main-advancedashboard">
        <Header title={"Orders"} link={"/employee/employee_details"} />
        <section className="AdvanceDashboard">
          <div className="col-xxl-12 col-xl-12  ">
            <div className="row MainRowsec">
              <section className="Tabels tab-radio tab-radio mt-5">
                <div className="">
                  {/* container */}
                  <div className="row">
                    <div className="d-flex">
                      <div className="add me-3">
                        {/* {isAllow.includes(6) ? ( */}
                        {/* <Link
                          // to="/employee/employee_details/add"
                          type="button"
                          className="btn btn-add pe-3"
                        >
                          <div onClick={() => handleShow()}>
                            <img src={plus} className="plus me-2 ms-0" alt="" />
                            Add Product
                          </div>
                        </Link> */}
                        {/* ) : (
                        <></>
                      )} */}
                      </div>

                      <div className="add">
                        <div className="dropdown">
                          <button
                            className="btn btn-columns dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src={colunms}
                              className="columns me-2 "
                              alt=""
                            />
                            Column Selection{" "}
                            <img src={top} className="top ms-1" alt="" />{" "}
                          </button>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col1")}
                                href="#"
                              >
                                Sr. No.
                                {visible?.col1 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col2")}
                                href="#"
                              >
                                Invoice No.
                                {visible?.col2 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col3")}
                                href="#"
                              >
                                Customer Name
                                {visible?.col3 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col4")}
                                href="#"
                              >
                                Order Date
                                {visible?.col4 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col5")}
                                href="#"
                              >
                                Delivery Date
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col6")}
                                href="#"
                              >
                                Product Name
                                {visible?.col5 ? (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-2"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col7")}
                                href="#"
                              >
                                Amount
                                {visible?.col7 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col8")}
                                href="#"
                              >
                                Order Status
                                {visible?.col8 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col9")}
                                href="#"
                              >
                                Payment Mode
                                {visible?.col9 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col10")
                                }
                                href="#"
                              >
                                Payment Status
                                {visible?.col10 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li> */}
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col11")
                                }
                                href="#"
                              >
                                Delivery Pincode
                                {visible?.col11 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) =>
                                  toggleColumn(event, "col12")
                                }
                                href="#"
                              >
                                Delivery Boy
                                {visible?.col12 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li>
                            {/* <li>
                              <Link
                                className="dropdown-item"
                                onClick={(event) => toggleColumn(event, "col13")}
                                href="#"
                              >
                                Action
                                {visible?.col13 ? (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    className="ms-4"
                                    icon="fa-solid fa-eye-slash"
                                  />
                                )}
                              </Link>
                            </li> */}
                          </ul>
                          {
                            usertype !== "Admin"
                              ? (
                                <button
                                  className="btn btn-columns dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    marginLeft: "5px",
                                    backgroundColor: "green",
                                  }}
                                >
                                  <img
                                    src={colunms}
                                    className="columns me-2 "
                                    alt=""
                                  />
                                  Change Order Status{" "}
                                  <img src={top} className="top ms-1" alt="" />{" "}
                                </button>
                              ) : (
                                <></>
                              )}
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {Object.keys(orderStatus).length > 0 &&
                              Object.entries(orderStatus).map(
                                ([id, status]) => (
                                  status?.value !== 1 && (
                                    <button
                                      className="dropdown-item"
                                      key={id}
                                      onClick={() =>
                                        handleChangeStatus(status.value)
                                      }
                                    >
                                      {status.label}{" "}
                                      {/* Accessing the label property */}
                                    </button>
                                  )
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-line mt-3"></div>
                    <div className="row mt-4">
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="d-flex">
                          <div className="show me-2">
                            <p className="show mt-1">Show</p>
                          </div>
                          <div className="number me-2">
                            <select
                              className="form-select form-select-sm"
                              aria-label=".form-select-sm example"
                              onChange={(e) => setperPage(e.target.value)}
                            >
                              {option?.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="entries">
                            <p className="show mt-1">entries</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-12 col-xl-12  col-lg-12  col-md-12 col-12">
                        <div className="row">

                        <div className="col-xxl-2 col-xl-2  col-lg-2  col-md-2 col-sm-2 col-2 mt-xl-0 mt-3 ">
                              <div className="sowing me-2">
                                <p className="show mt-2">{`Showing ${Math.min(
                                  (currentPage - 1) * perPage + 1
                                )} to ${Math.min(
                                  currentPage * perPage,
                                  data?.data?.totalEntries
                                )} of ${data?.data?.totalEntries} entries`}</p>
                              </div>
                            </div>
                          <div className="col-xxl-2 col-xl-2  col-lg-2  col-md-2 col-sm-2 col-2 mt-xl-0 mt-3" >
                            <div className="num me-2" >
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Customer Name"
                                id=""
                                value={customerName}
                                onChange={(e) => {
                                  setCustomerName(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-2 col-xl-2  col-lg-2  col-md-2 col-sm-2 col-2 mt-xl-0 mt-3" >
                            <div className="num me-2" >
                              <Select
                                options={products}
                                placeholder="Select Product"
                                value={searchProduct}
                                onChange={(e) => {
                                  setSearchProduct(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-2 col-xl-2  col-lg-2  col-md-2 col-sm-2 col-2 mt-xl-0 mt-3" >
                            <div className="num me-2" >
                              <Select
                                options={orderStatus}
                                placeholder="Select Order Status"
                                value={searchOrderStatus}
                                onChange={(e) => {
                                  setSearchOrderSatatus(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-2 col-xl-2  col-lg-2  col-md-2 col-sm-2 col-2 mt-xl-0 mt-3" >
                            <div className="num me-2" >
                              <Select
                                options={[
                                  { label: "online", value: "online" },
                                  { label: "cod", value: "cod" },
                                ]}
                                // value={searchPaymentStatus}
                                placeholder="Payment Status"
                                value={searchPaymentStatus}
                                onChange={(e) => {
                                  setSearchPaymentSatatus(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-2 col-xl-2  col-lg-2  col-md-2 col-sm-2 col-2 mt-xl-0 mt-3" >
                            <div className="num me-2" >
                            <input
                                type="date"
                                className="form-control"
                                placeholder="Date"
                                id=""
                                value={searchDate}
                                onChange={(e) => {
                                  setSearchDate(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-xxl-2 col-xl-2  col-lg-2  col-md-2 col-sm-2 col-2 mt-xl-0 mt-3" >
                            <div className="Search-1">
                              <button
                                type="button"
                                onClick={() => {
                                  setSearchPaymentSatatus("");
                                  setSearchOrderSatatus("");
                                  setSearchProduct("");
                                  setCustomerName("");
                                  setReset(!reset);
                                }}
                                className="btn btn-reset"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-line mt-3"></div>
                  <div className="row mt-3">
                    <div className="data table-responsive">
                      <Table striped bordered hover responsive center>
                        <thead>
                          <tr className="">
                            <th>
                              <input
                                type="checkbox"
                                value="selectAll"
                                checked={
                                  allChecked.length === selectAllChecked.length
                                }
                                onChange={handleChange}
                                id="selectAll"
                                className="me-1"
                              ></input>
                              Select
                            </th>
                            {visible.col1 && <th className="sr">Sr. No.</th>}
                            {visible.col2 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Invoice No.
                              </th>
                            )}
                            {visible.col3 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Customer Name
                              </th>
                            )}

                            {visible.col4 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Order Date
                              </th>
                            )}
                            {visible.col5 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Delivery Date
                              </th>
                            )}{" "}
                            
                            {visible.col6 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Delivery Time
                              </th>
                            )}{" "}
                            {visible.col14 && user?.id === 1 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Seller Name
                              </th>
                            )}
                            {visible.col6 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Product Name
                              </th>
                            )}
                            {visible.col7 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Amount
                              </th>
                            )}
                            {visible.col8 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Order Status
                              </th>
                            )}
                            {visible.col9 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Payment Mode
                              </th>
                            )}
                            {/* {visible.col10 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Payment Status
                              </th>
                            )} */}
                            {visible.col11 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Delivery Pincode
                              </th>
                            )}
                            {/* {visible.col12 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Action
                              </th>
                            )} */}
                            {visible.col13 && (
                              <th
                                className="tax-name"
                                style={{ width: "200px" }}
                              >
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {/* {isAllow.includes(5) ? ( */}
                          {data?.data?.data?.map((d, index) => {
                            return (
                              <tr className="" key={index}>
                                <td>

                                {d?.order_status_id === 4 || d?.order_status_id === 6 ? (
                                      <></>
                                ) : (
                                  <input
                                  type="checkbox"
                                  className="form-check-input"
                                  value={d?.id}
                                  name="perselected"
                                  checked={selectAllChecked.includes(d?.id)}
                                  onChange={handleChange}
                                  id="flexCheckDefault"
                                ></input>
                                )}
                                 
                                </td>
                                {visible.col1 && <td>{++index}.</td>}
                                {visible.col2 && (
                                  <td style={{ width: "200px" }}>
                                    {d?.invoice_number}
                                  </td>
                                )}
                                {visible.col3 && <td>{d?.User?.first_name}</td>}
                                {visible.col4 && (
                                  <td>{d?.createdAt.split("T")[0]}</td>
                                )}
                                {visible.col5 && (
                                  <td>{d?.delivery_date?.split("T")[0]}</td>
                                )}
                                {visible.col6 && (
                                  <td>{timeShow(d?.delivery_date)}</td>
                                )}
                                {visible.col14 && user.id === 1 && (
                                  <td>
                                    {/* {d?.orders_details?.map( */}
                                      {/* (orderDetail, index) => ( */}
                                        <div key={index}>
                                          {d?.seller?.first_name}
                                        </div>
                                      {/* ) */}
                                    {/* )} */}
                                  </td>
                                )}
                                {visible.col6 && (
                                  <td>
                                    {/* {d?.orders_details?.map(
                                      (orderDetail, index) => ( */}
                                        <div key={index}>
                                          {d?.product?.name}
                                        </div>
                                      {/* )
                                    )} */}
                                  </td>
                                )}
                                {visible.col7 && <td>{Number(d?.price ?? 0) + (Number(d?.price ?? 0) * Number(d?.tax_percent ?? 0) / 100)}</td>}
                                {visible.col8 && (
                                  <td>{d?.order_status?.order_status}</td>
                                )}
                                {visible.col9 && <td>{d?.order?.payment_mode.toUpperCase()}</td>}

                                {/* {visible.col10 && (
                                  <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        role="switch"
                                        disabled={user.id === 1}
                                        style={{
                                          backgroundColor: d.payment_status
                                            .status
                                            ? "green"
                                            : "red",
                                        }}
                                        checked={d.payment_status.status}
                                        onChange={() => {
                                          ChangeStatus(d.id);
                                        }}
                                        id={`flexSwitchCheckDefault${d?.id}`}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                      >
                                        {d.payment_status.status
                                          ? "Paid"
                                          : "Unpaid"}
                                      </label>
                                    </div>
                                  </td>
                                )} */}
                                {visible.col11 && (
                                  <td>{d?.order?.users_address?.pincode?.name}</td>
                                )}
                                {/* {visible.col12 && (
                                   <td><button className="btn btn-info view-btn_holder" onClick={() => handleClick(d)}>View Details</button></td>
                                  // <td><button className="btn btn-info view-btn_holder" onClick={() => {setOrderData(d); setModalShow(true);}  }>View Details</button></td>
                                )} */}

                                {/* {visible.col6 && <td>{d?.password}</td>} */}
                                {/* {visible.col6 && <td>******</td>} */}
                                {/* {visible.col7 && (
                                  <td>
                                    {d?.id === 1 ? (
                                      <></>
                                    ) : (
                                      <div className="form-check form-switch">
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          role="switch"
                                          checked={d.status}
                                          onChange={() => {
                                            ChangeStatus(d.id);
                                          }}
                                          id={`flexSwitchCheckDefault${d?.id}`}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={`flexSwitchCheckDefault${d?.id}`}
                                        >
                                          {d.status ? "Active" : "Inactive"}
                                        </label>
                                      </div>
                                    )}
                                  </td>
                                )} */}
                                {visible.col13 && (
                                  <td>

                                    <div className="d-flex">
                                      {/* <Button
                                          onClick={() => handleShow1(d?.id)}
                                          type="button"
                                          className="btn btn-primary me-1"
                                        >
                                          <img
                                            src={pen}
                                            className="pen"
                                            alt=""
                                          />
                                        </Button> */}

                                      {/* {isAllow.includes(8) ? ( */}
                                      {/* <button
                                          onClick={() => {
                                            showDeleteRecord(d?.id, d?.name);
                                          }}
                                          type="button"
                                          className="btn btn-danger"
                                        >
                                          <img
                                            src={basket}
                                            className="pen"
                                            alt=""
                                          />
                                        </button> */}
                                      {/* ) : (
                                        <></>
                                      )} */}
                                    </div>

                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>



      

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tables;
